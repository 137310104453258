html {
  background-color: #c4b3b3;
}
article {
  font-family: Open Sans;
  font-weight: 100;
  font-size: 20px;
  text-align: center;
  background-color: #c4b3b3;
  width: 700px;
  padding: 50px;
  margin: 0 auto;
}
a {
  color: #fff;
  font-weight: bold;
}
