html {
  background-color: #c4b3b3;
}

article {
  text-align: center;
  width: 700px;
  background-color: #c4b3b3;
  margin: 0 auto;
  padding: 50px;
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 100;
}

a {
  color: #fff;
  font-weight: bold;
}

.gPMrEW_icon_container > * {
  border-radius: 5px;
  padding-top: .5em;
  box-shadow: 1px 1px 6px 2px #9b8a8a;
}

.gPMrEW_icon_container > :last-child, .gPMrEW_icon_container > :first-child {
  flex: 0 0 30px;
}

/*# sourceMappingURL=index.156f3869.css.map */
